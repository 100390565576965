import { uploadImage } from '@/api/upload'

export default class UploadAdapter {
  constructor(loader) {
    this.loader = loader
  }

  upload() {
    return this.loader.file
      .then(file => new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('type', 'image')
        formData.append('folder_name', 'interviews')

        uploadImage(formData).then(({ data }) => {
          const url = process.env.VUE_APP_BASE_STORAGE + '/' + data.url
          resolve({
            default: url
          })
        })
      }))
  }
}

export const uploader = function(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
    new UploadAdapter(loader)
}
