<template>
  <div v-loading="dataLoading" class="home-page">
    <div class="app-container">
      <!-- Layout -->
      <el-row :gutter="20" type="flex" justify="center">
        <!-- Content -->
        <el-col :md="20" :lg="20" :sm="24" :xs="24">
          <el-form ref="interviewForm" :model="interviewForm" :rules="interviewRules">
            <!--- Row 1 --->
            <el-row :gutter="20">
              <el-col :md="16">
                <div class="brand-color mb-5">タイトル</div>
                <el-form-item prop="title">
                  <el-input v-model="interviewForm.title" size="small" tabindex="1" />
                </el-form-item>
              </el-col>
              <el-col :md="8">
                <div class="brand-color mb-5">プッシュ通知</div>
                <el-row :gutter="10">
                  <el-col :md="12">
                    <el-button
                      size="small"
                      class="w-100 btn-custom"
                      :class="{'pressed': interviewForm.push_status}"
                      @click="togglePushStatus(1)"
                    >
                      ON
                    </el-button>
                  </el-col>
                  <el-col :md="12">
                    <el-button
                      size="small"
                      class="w-100 btn-custom"
                      :class="{'pressed': !interviewForm.push_status}"
                      @click="togglePushStatus(0)"
                    >
                      OFF
                    </el-button>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>

            <!-- Row 2 -->
            <div class="brand-color mb-5">写真</div>
            <el-form-item prop="image">
              <el-row type="flex">
                <el-col :span="5">
                  <label id="form-upload" class="form-upload" :class="{preview: urlPreview}">
                    <div v-if="urlPreview">
                      <img :src="urlPreview" @click="imagePreview = true">
                      <div class="middle">
                        <i class="el-icon-zoom-in" style="margin-right:5px" @click="imagePreview = true" />
                      </div>
                    </div>
                    <div v-else>
                      <i class="el-icon-plus" for="uploadInput" />
                      <input id="uploadInput" type="file" @change="handleFileUpload">
                    </div>
                  </label>
                </el-col>
                <el-col v-if="urlPreview" :span="3" style="align-self: flex-end">
                  <input ref="file" type="file" style="display: none" @change="handleFileUpload">
                  <el-button size="small" type="primary" @click="$refs.file.click()">イメージを変更</el-button>
                </el-col>
              </el-row>
            </el-form-item>

            <!-- Dialog Image -->
            <el-dialog v-model="imagePreview" title="プレビュー">
              <el-image :src="urlPreview" fit="cover" style="width:100%;height:100%" />
            </el-dialog>
            <!--- Row 3 --->
            <el-row type="flex" :gutter="10" justify="between">
              <el-col :span="12">
                <div class="brand-color mb-5">本文</div>
              </el-col>
              <el-col :span="12" style="position:relative">
                <el-button size="small" style="position:absolute;right: 0;bottom: 10px" @click="contentPreview = true">プレビュー</el-button>
              </el-col>
            </el-row>
            <el-form-item prop="content">
              <ckeditor v-model="interviewForm.content" :editor="editor" :config="editorConfig" />
            </el-form-item>

            <!--- Dialog Content --->
            <el-dialog v-model="contentPreview" title="プレビュー">
              <div class="content-preview" v-html="interviewForm.content" />
            </el-dialog>

            <!--- Row 4 --->
            <el-row :gutter="10" class="mt-50 mb-50 text-center">
              <el-col>
                <el-button v-if="isCreateForm" type="primary" size="small" @click="handleCreate">作成</el-button>
                <el-button v-else type="primary" size="small" @click="handleUpdate">保存</el-button>
                <el-button type="info" size="small" @click="handleCancel">キャンセル</el-button>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { uploadImage } from '@/api/upload'
import { createInterview, fetchInterview, updateInterview } from '@/api/interview'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { uploader } from '@/plugins/ckeditor'
export default {
  name: 'InterviewDetail',
  props: {
    isCreateForm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const validateImage = (rule, value, callback) => {
      if (this.urlPreview == null) {
        callback(new Error('「写真」を入力してください'))
      } else {
        callback()
      }
    }
    return {
      editor: ClassicEditor,
      editorConfig: {
        extraPlugins: [uploader]
      },
      imagePreview: false,
      dataLoading: false,
      interviewId: this.$route.params.id,
      interviewForm: {
        title: null,
        content: null,
        image: null,
        push_status: 1
      },
      interviewRules: {
        title: [{ required: true, trigger: 'blur', message: '「タイトル」を入力してください' }],
        content: [{ required: true, trigger: 'blur', message: '「本文」を入力してください' }],
        image: [{ trigger: 'blur', validator: validateImage }]
      },
      imageFile: null,
      urlPreview: null,
      contentPreview: false
    }
  },
  created() {
    if (!this.isCreateForm) {
      this.getData()
    }
  },
  methods: {

    getData() {
      fetchInterview(this.$route.params.id).then((response) => {
        if (response.data) {
          this.interviewForm.title = response.data.title
          this.interviewForm.content = response.data.content
          this.interviewForm.image = response.data.image
          this.interviewForm.push_status = response.data.push_status
          this.urlPreview = this.interviewForm.image ? process.env.VUE_APP_BASE_STORAGE + '/' + this.interviewForm.image : null
          // console.log(process.env)
        }

        setTimeout(() => {
          this.dataLoading = false
        }, 0.1 * 1000)
      })
    },

    createData() {
      this.dataLoading = true
      createInterview(this.interviewForm).then((response) => {
        this.$notify({
          title: 'Success',
          message: 'データは正常に作成されました',
          type: 'success',
          duration: 2000
        })

        setTimeout(() => {
          this.dataLoading = false
          this.handleCancel()
        }, 0.1 * 1000)
      })
    },

    updateData() {
      this.dataLoading = true
      updateInterview(this.interviewId, this.interviewForm).then((response) => {
        this.$notify({
          title: 'Success',
          message: 'データは正常に更新されました',
          type: 'success',
          duration: 2000
        })

        setTimeout(() => {
          this.dataLoading = false
          this.handleCancel()
        }, 0.1 * 1000)
      })
    },

    upload(formData, funcHandle) {
      uploadImage(formData).then((response) => {
        this.interviewForm.image = response.data.url
        funcHandle()
      })
    },

    handleCreate() {
      this.$refs.interviewForm.validate((valid) => {
        if (valid) {
          if (this.imageFile) {
            const formData = new FormData()
            formData.append('file', this.imageFile)
            formData.append('folder_name', 'interviews')
            formData.append('type', 'image')
            this.upload(formData, this.createData)
          } else {
            this.createData()
          }
        } else {
          console.log('Error Submit')
          return false
        }
      })
    },

    handleUpdate() {
      this.$refs.interviewForm.validate((valid) => {
        if (valid) {
          if (this.imageFile) {
            const formData = new FormData()
            formData.append('file', this.imageFile)
            formData.append('folder_name', 'interviews')
            formData.append('type', 'image')
            if (!this.isCreateForm) {
              formData.append('old_file', this.interviewForm.image)
            }
            this.upload(formData, this.updateData)
          } else {
            this.updateData()
          }
        } else {
          console.log('Error submit')
        }
      })
    },
    handleCancel() {
      const matched = this.$route.matched.filter((item) => item.name)
      this.$router.push(matched[0].redirect)
    },
    handleFileUpload(e) {
      const file = e.target.files[0]
      this.imageFile = file
      this.urlPreview = URL.createObjectURL(file)
    },

    togglePushStatus(val) {
      this.interviewForm.push_status = val
    }
  }
}
</script>
<style lang="scss" scoped>
.btn-custom{
   border-radius: 7px;
  background-image: linear-gradient(
    rgba(255, 255, 255, 1),
    rgba(187, 183, 178, 1)
  );
  color: #495057;
}
.btn-custom.pressed {
  background-image: linear-gradient(
    rgba(184, 227, 207, 1),
    rgb(14, 92, 238)
  );
}
</style>
